import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext.js";

import About from "./pages/About.js";
import Home from "./pages/Home.js";
import Profile from "./pages/Profile.js";
import P404 from "./pages/P404.js";
import Chapters from "./titles/Chapters.jsx";
import Titles from "./titles/Titles.jsx";
import Signup from	"./components/Signup";
import Signin from	"./components/Signin";
import ProtectedRoute from "./ProtectedRoute.js";
import ForgotPass from "./components/ForgotPass.jsx";
import TitlesList from "./pages/TitlesList.js";

function App() {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Routes>
					<Route path="*" element={<P404/>}/>
					<Route exact path="/" element={<Home/>}/>
					<Route path="/home" element={<Home/>}/>
					<Route path="/about" element={<About/>}/>
					<Route path="/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>} />
					<Route path="/sign-up" element={<Signup/>}/>
					<Route path="/sign-in" element={<Signin/>}/>
					<Route path="/forgot-password" element={<ForgotPass/>} />
					<Route exact path="/title/:title" element={<Titles/>}/>
					<Route exact path="/titles" element={<TitlesList/>}/>
					<Route path="/title/:title/chapter/:chapter" element={<Chapters/>}/>
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	)
}

export default App;
