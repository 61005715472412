import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

import Header from "./Header";
import AlertBox from "./AlertBox";

import "../css/auth.css";
import { Link, useNavigate } from "react-router-dom";

function ForgotPass() {
    const [isLoading, setLoading] = useState(false);

    const emailRef = useRef();
    const { resetPass, currentUser } = useAuth();

    const redirect = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setLoading(true);
            await resetPass(emailRef.current.value);
            redirect("/sign-in");
        }
        catch {
            document.getElementById("alertbox").classList.add("showAlert");
        }
        setLoading(false);
    }
    useEffect(() => {
        if (currentUser) {
            return redirect("/profile")
        }
    })

    return (
        <main>
            <Header/>
            <form action="POST" className="auth">
                <div className="auth__container">
                    <div className="auth__general">
                        <img src={process.env.PUBLIC_URL + "/img/logo/NedoLib-1024x1024.webp"} alt="Logo" width="96" height="96"/>
                        <h1>Welcome, back</h1>
                        {AlertBox("Error", "Failed to reset password")}
                    </div>
                    <div id="sign-in" className="auth__inputs">
                        <div className="inputs">
                            <input className="inputs__input" id="email" type="text" autoComplete="off" ref={emailRef} required/>
                            <span className="inputs__text">Email</span>
                        </div>
                    </div>
                    <div className="auth__additional-buttons">
                        <button className="additional-buttons__submitbtn" disabled={isLoading} onClick={handleSubmit}>Reset</button>
                        <Link to="/sign-in">Remember the password?</Link>
                    </div>
                </div>
            </form>
        </main>
    )
}

export default ForgotPass;