import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Header from "../components/Header";
import titlesData from  "./titlesData.json";

import "../css/titles.css"

function Titles() {
    let titleName = useParams().title.toLowerCase();
    let titleIndex = -1;
    let index = 0;
    titlesData.forEach(title => {
        if (title.titleName.toLowerCase() === titleName) {
            titleIndex = index;
        }
        else {
            index++;
        }
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (titleIndex === -1) {
            return navigate("/404");
        }
    })
    
    if (titleIndex !== -1) {
        return (
            <main>
                <Header />
                <section className="titles">
                    <div className="titles__block block-title">
                        <h1 className="block-title__title">{titlesData[titleIndex].titleName}</h1>
                        <span className="block-title__line"></span>
                        <ul className="block-title__body" id="chaptersList">
                            {
                                titlesData[titleIndex].pages.map((element, i) => {
                                    return (
                                        <li key={i} className="block-title__item"><Link to={"/title/" + titleName + "/chapter/" + (i + 1)} className="block-title__link">Chapter {i + 1}</Link></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </section>
                <style>
                    {`
                        body {
                            background-image: url("${titlesData[titleIndex].bgImg}");
                            background-position: top;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-attachment: fixed;
                        }
                    `}
                </style>
            </main>
        )
    }
}

export default Titles;