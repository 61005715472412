import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../css/default.css";
import "../css/header.css";

function Header() {
    const [burger_btn, setBurger] = useState("");
    const [mobile_navbar, setMobile_navbar] = useState("");
    
    function burger_open() {
        burger_btn === "burger-menu-active" ? setBurger(" ") : setBurger("burger-menu-active");
        mobile_navbar === "active-burger-menu" ? setMobile_navbar(" ") : setMobile_navbar("active-burger-menu");
    }

    return (
        <header id="header" className="header">
            <Link to="/" className="header__left-side left">
                <img className="left__logo" src={process.env.PUBLIC_URL + "/img/logo/NedoLib-1024x1024.webp"} width="64" height="64" alt="Logo"/>
                <span className="left__logo-text">NedoLib</span>
            </Link>
            <div className="header__middle-side middle">
                <nav className={"middle__navigation navbar " + mobile_navbar}>
                    <ul className="navbar__body">
                        <li className="navbar__item">
                            <Link to="/" className="navbar__link">HOME</Link>
                        </li>
                        <li className="navbar__item">
                            <Link to="/about" className="navbar__link">ABOUT</Link>
                        </li>
                        {/* <li className="navbar__item">
                            <Link to="/title/diaw" className="navbar__link">DIAW</Link>
                        </li>
                        <li className="navbar__item">
                            <Link to="/title/horimiya" className="navbar__link">HORIMIYA</Link>
                        </li> */}
                        <li className="navbar__item">
                            <Link to="/titles" className="navbar__link">TITLES</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="header__right-side right">
                <div className={"right__burger " + burger_btn} onClick={burger_open}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="right__social">
                    <a href="https://discord.gg/k72C4Gj6HX" target="_blank" rel="noreferrer">
                        <img src={process.env.PUBLIC_URL + "/img/icons/discord.svg"} alt="Discord-logo" />
                    </a>
                    <a href="https://t.me/iknowalphabet" target="_blank" rel="noreferrer">
                        <img src={process.env.PUBLIC_URL + "/img/icons/telegram.svg"} alt="Telegram-logo" />
                    </a>
                    <a href="http://instagram.com/Lolp1ke" target="_blank" rel="noreferrer">
                        <img src={process.env.PUBLIC_URL + "/img/icons/instagram.svg"} alt="Instagram-logo" />
                    </a>
                </div>
                <Link className="right__link" to="/sign-up">
                    <img src={process.env.PUBLIC_URL + "/img/icons/profile.svg"} alt="Profile-icon" className="icon"/>
                </Link>
            </div>
        </header>
    )
}

export default Header;