import React from "react";

import Header from "../components/Header";
import ProfileContent from "../components/ProfileContent";

function Profile() {
    return (
        <>
            <Header/>
            <ProfileContent/>
        </>
    )
}

export default Profile;