import React from "react";

import Header from "../components/Header";
import P404Content from "../components/P404Content";

function P404() {
    return (
        <>
            <Header/>
            <P404Content/>
        </>
    )
}

export default P404;