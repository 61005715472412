import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { doc, setDoc } from "firebase/firestore";
import { fireStoreDb } from "../firebase";

import Header from "./Header";
import AlertBox from "./AlertBox";

import "../css/auth.css";

function Signup() {
    let maxLogin = 16;
    let maxPass = 16;
    let minLogin = 3;
    let minPass = 8;
    const [isLoading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    // const usersCollectionRef = collection(fireStoreDb, "users");

    // function getUsers() {
    //     getDocs(usersCollectionRef)
    //         .then(response => {
    //             const usersList = response.docs.map(doc => ({
    //                 id: doc.id,
    //                 data: doc.data()
    //             }))
    //             setUsers(usersList);
    //         })
    //         .catch(error => console.log(error.message));
    // }

    // useEffect(() => {
    //     getUsers();
    // }, []);

    console.log(users);

    const emailRef = useRef();
    const nicknameRef = useRef();
    const passRef = useRef();
    const { signup, currentUser } = useAuth();

    const redirect = useNavigate();

    async function addDataToDb() {
        // await addDoc(usersCollectionRef, {
        //     login: loginRef.current.value,
        //     email: emailRef.current.value,
        //     firstName: "",
        //     lastName: ""
        // })
        //     .then(response => console.log(response))
        //     .catch(error => console.log(error.message));
        await setDoc(doc(fireStoreDb, "users", emailRef.current.value), {
            nickname: nicknameRef.current.value,
            email: emailRef.current.value,
            firstName: "",
            lastName: "",
            profilePicURL: "",
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (nicknameRef.current.value.length < 3 || passRef.current.value.length < 8) {
            document.getElementById("alertbox").classList.add("showAlert");
        }
        else {
            document.getElementById("alertbox").classList.remove("showAlert");
            try {
                setLoading(true);
                await signup(emailRef.current.value, passRef.current.value);
                addDataToDb();
                redirect("/profile");
            }
            catch {
                document.getElementById("alertbox").classList.add("showAlert");
            }
        }
        setLoading(false);
    }  

    useEffect(() => {
        if (currentUser) {
            return redirect("/profile")
        }
    })

    return (
        <>
            <Header/>
            <form action="POST" className="auth">
                <div className="auth__container">
                    <div className="auth__general">
                        <img src={process.env.PUBLIC_URL + "/img/logo/NedoLib-1024x1024.webp"} alt="Logo" width="96" height="96"/>
                        <h1>Welcome</h1>
                        <p>YEAHHHH, commenting is coming</p>
                    </div>
                    <div id="sign-up" className="sign-up__inputs">
                        <div className="inputs">
                            <input className="inputs__input" ref={emailRef} type="email" autoComplete="off" required/>
                            <span className="inputs__text">Email</span>
                        </div>
                        <div className="inputs">
                            <input className="inputs__input" ref={nicknameRef} type="text" autoComplete="off" maxLength={maxLogin} minLength={minLogin} required/>
                            <span className="inputs__text">Nickname</span>
                        </div>
                        <div className="inputs">
                            <input className="inputs__input" ref={passRef} type="password" autoComplete="off" maxLength={maxPass} minLength={minPass} required/>
                            <span className="inputs__text">Password</span>
                        </div>
                    </div>
                    <div className="auth__additional-buttons">
                        <button className="additional-buttons__submitbtn" disabled={isLoading} onClick={handleSubmit}>Sign Up</button>
                        <Link to="/sign-in">Already have an account?</Link>
                    </div>
                </div>
            </form>

            {AlertBox("Warning!!!", "Minimum length of Login 3 symbools</br>Minimum length of Password 8 symbols")}
        </>
    )
}

export default Signup;