import React from "react";

import Header from "../components/Header";
import Hero from "../components/Hero";
import ListOfTitles from "../components/ListOfTitles";

function TitlesList() {
    return (
        <>
            <Header/>
            {Hero("Made by Kazakh", "There are list of the titles made (translated to kazakh) in Kazakhstan")}
            <ListOfTitles/>
        </>
    )
}

export default TitlesList;