import React from "react";

import "../css/alertBox.css";

function AlertBox(alertTitle, alertMessage) {
    return (
        <div id="alertbox" className="alertBox">
            <div className="alertBox__title">
                <img src={process.env.PUBLIC_URL + "/img/icons/exclamination.svg"} alt="!-icon" />
                <h2>{alertTitle}</h2>
                <img src={process.env.PUBLIC_URL + "/img/icons/x.svg"} alt="x-icon" onClick={() => {
                    document.getElementById("alertbox").classList.remove("showAlert");
                }}/>
            </div>
            <span className="alertBox__line"></span>
            <p className="alertBox__text" dangerouslySetInnerHTML={{__html: alertMessage}}></p>
        </div>
    )
}

export default AlertBox;