import React from "react";

import Header from  "../components/Header";
import Hero from "../components/Hero";

function Home() {
    return (
        <>
            <Header/>
            {Hero("Kazakh manga website", "Yeah, Finally <a href=\"https://instagram.com/lolp1ke\" class=\"hero__text-link\" target=\"_blank\" rel=\"noreferrer\">I</a> finished Nedolib")}
            <style>
                {`
                    body {
                        background-image: url("/img/bg.webp");
                        background-position: bottom;
                        background-repeat: no-repeat;
                        background-attachment: fixed;
                        background-color: #ffffff;
                    }
                `}
            </style>
        </>
    )
}

export default Home;