import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import "../css/about.css";
import "../modules/swiperjs/swiper-bundle.min.css";

function AboutContent() {
    return (
        <section className="about">
            <div className="about__info info">
                <img src={process.env.PUBLIC_URL + "/img/profile.webp"} width="320" alt="pic-of-cool-guy" className="info__pic"/>
                <div className="info__block block">
                    <h2 className="block__title">Web Developer</h2>
                    <div className="block__body">
                        <div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>Birthday:</b>
                                <p>20 april 2006</p>
                            </div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>Inst:</b>
                                <a href="https://instagram.com/lolp1ke" target="_blank" rel="noreferrer">Lolp1ke</a>
                            </div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>Phone:</b>
                                <p>Not available</p>
                            </div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>City:</b>
                                <p>Astana, Kazakhstan</p>
                            </div>
                        </div>
                        <div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>Age:</b>
                                <p>Math yourself</p>
                            </div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>Degree:</b>
                                <p><s>Senior</s> developer</p>
                            </div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>IQ:</b>
                                <p>More than you have</p>
                            </div>
                            <div className="block__item">
                                <img src={process.env.PUBLIC_URL + "/img/icons/arrow.svg"} alt="arrow-icon" />
                                <b>Available in:</b>
                                <a href="https://t.me/iknowalphabet" target="_blank" rel="noreferrer" >Telegram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about__reviews reviews">
                <div className="reviews__label">
                    <div className="reviews__text">
                        <p>Reviews</p>
                        <span></span>
                    </div>
                    <h1 className="reviews__title">Only honest ratings</h1>
                </div>

                <div id="swiper-rating" className="swiper">
                    <Swiper className="swiper-wrapper"
                    slidesPerView={3} 
                    loop={true}
                    autoplay={
                        {
                            delay: 3000,
                            disableOnInteraction: false
                        }
                    }
                    breakpoints={{
                        375: {
                            slidesPerView: 1
                        },
                        768: {
                            slidesPerView: 2
                        },
                        1024: {
                            slidesPerView: 3
                        }
                    }}
                    modules={[Autoplay]}
                    >
                        <SwiperSlide className="swiper-slide">
                            <div className="swiper-slide-item">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                </div>
                                <p>I'll write it later</p>
                                <h1>Makhmetov Arlan</h1>
                                <p>57 AR Genshin</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="swiper-slide-item">
                                <div>
                                <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                </div>
                                <p>
                                    It's a pleasure to see how kazakh language is getting its distinguished attention, and it's an absolutely time-worthy translation of such a good manga. As it was mentioned, the translator managed to do a great job to keep the calming vibe of the original manga and interpret it in kazakh.
                                </p>
                                <h1>Zhakupova Aruzhan</h1>
                                <p>thedrownedpeter</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="swiper-slide-item">
                                <div>
                                <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                    <img src={process.env.PUBLIC_URL + "/img/icons/star.svg"} alt="star-icon" />
                                </div>
                                <p>
                                    Horimiya is a work that fills with a sense of calmness and sweetness. This manga deserves more attention, and translating it into Kazakh is a great idea.
                                    <br></br>
                                    <br></br>
                                    P.S. I am waiting for the translation of "Адам ара"
                                </p>
                                <h1>Antaibekov Erasyl</h1>
                                <p>Mangaka</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    )
}

export default AboutContent;