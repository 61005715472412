import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { fireStoreDb } from "../firebase";
import { collection, doc, getDoc } from "firebase/firestore";

import "../css/default.css";
import "../css/profile.css";


function ProfileContent() {
    const { currentUser, logout } = useAuth();

    const [userDataref, setUserData] = useState();
    const [profileImg, setNewProfileImg] = useState(process.env.PUBLIC_URL + "/img/logo/NedoLib-1024x1024.webp");

    const redirect = useNavigate();
    
    const usersCollectionRef = collection(fireStoreDb, "users");
    

    async function handleLogout() {
        try {
            await logout();
            return redirect("/sign-in");
        } 
        catch {
            document.getElementById("alertbox").classList.add("showAlert");
        }
    }

    let items = document.getElementsByClassName("actions-type__item");

    function changeClass(currentSection) {
        for (let i = 0; i < items.length; i++) {
            items[i].classList.remove("active-section")
        }
        items[currentSection].classList.toggle("active-section")
    }

    function getDataForCurrentUser() {
        const docRef = doc(usersCollectionRef, currentUser.email);
        getDoc(docRef)
            .then(response => {
                if (response.exists()) {
                    setUserData(response.data());
                    setNewProfileImg(response.data().profilePicURL);
                }
                else {
                    console.log("User data not found");
                }
            })
            .catch(error => console.log(error.message));
    }

    useEffect(() => {
        getDataForCurrentUser();
    }, [])

    return (
        <section className="profile">
            <div className="profile__container profile-sections">
                <div className="profile-sections__top section-top">
                    <button className="section-top__log-out-button" onClick={handleLogout}>logOut</button>
                    <p className="section-top__text">{(userDataref) ? userDataref.nickname : "Chelik"}</p>
                    <img src={profileImg} alt="profile-img" className="section-top__profile-img" />
                    <img src={process.env.PUBLIC_URL + "/img/icons/edit.svg"} alt="edit-icon" className="section-top__edit-button" />
                </div>
                <div className="profile-sections__bottom section-bottom">
                    <div className="section-bottom__main-content">
                        content
                    </div>
                    <div className="section-bottom__actions actions-type">
                        <div className="actions-type__item active-section" onClick={() => changeClass(0)}><p>All</p></div>
                        <div className="actions-type__item" onClick={() => changeClass(1)}><p>Reading</p></div>
                        <div className="actions-type__item" onClick={() => changeClass(2)}><p>In Plans</p></div>
                        <div className="actions-type__item" onClick={() => changeClass(3)}><p>Throwns</p></div>
                        <div className="actions-type__item" onClick={() => changeClass(4)}><p>Read</p></div>
                        <div className="actions-type__item" onClick={() => changeClass(5)}><p>Favorites</p></div>
                        <div className="actions-type__item" onClick={() => changeClass(6)}><p>Settings</p></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProfileContent;