import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import Hero from "./Hero";

import "../modules/swiperjs/swiper-bundle.min.css";
import "../css/404.css";
import "../css/hero.css";

function P404Content() {
    return (
        <section className="not-found">
            {Hero("<p style=\"font-size: 8.5rem\">Error 404</p>", "The page you want to visit is not there, most likely it is the next chapter.")}

            <div id="swiper-404" className="not-found swiper">
                <Swiper className="swiper-wrapper" 
                slidesPerView={1} 
                loop={true}
                preloadImages={false}
                pagination={
                    {
                        clickable: true
                    }
                }
                autoplay={
                    {
                        delay: 5000, 
                        disableOnInteraction: false
                    }
                }
                modules={[Autoplay, Pagination]}
                >
                    <SwiperSlide className="swiper-slide"><img className="swiper-slide-pic" src={process.env.PUBLIC_URL + "/img/404/01.webp"} alt="pic 1"/></SwiperSlide>
                    <SwiperSlide className="swiper-slide"><img className="swiper-slide-pic" src={process.env.PUBLIC_URL + "/img/404/02.webp"} alt="pic 2"/></SwiperSlide>
                    <SwiperSlide className="swiper-slide"><img className="swiper-slide-pic" src={process.env.PUBLIC_URL + "/img/404/03.webp"} alt="pic 3"/></SwiperSlide>
                    <SwiperSlide className="swiper-slide"><img className="swiper-slide-pic" src={process.env.PUBLIC_URL + "/img/404/04.webp"} alt="pic 4"/></SwiperSlide>
                </Swiper>

                <div className="swiper-pagination"></div>
            </div>

            <div className="not-found__information block-404">
                <div className="block-404__left info">
                    <h1 className="info__title">A couple of details about me</h1>
                    <p className="info__text">(There should be a text here, but I'm too lazy to write it)</p>
                    <div className="info__rectangle rect">
                        <img src={process.env.PUBLIC_URL + "/img/icons/quotation.svg"} alt="quotation-icon" /><p className="rect__quotation">Humanity is mistake. Be a sunflower.</p>
                        <div className="rect__photo-name">
                            {/* <img className="rect__photo" src="/assets/img/404/photo.webp" alt="photo"/> */}
                            <h1 className="rect__name">Bekzhanov Alibek</h1>
                        </div>
                    </div>
                </div>
                <div className="block__right detail-info">
                    <h1 className="detail-info__title">Information</h1>
                    <span className="detail-info__line"></span>
                    <div className="detail-info__item">
                        <p>Cleaner</p>
                        <p>Lolp1ke (It's me)</p>
                    </div>
                    <div className="detail-info__item">
                        <p>Typer</p>
                        <p>Alibek (That's me too)</p>
                    </div>
                    <div className="detail-info__item">
                        <p>Next Chapter</p>
                        <p>maybe tomorrow</p>
                    </div>
                    <div className="detail-info__item">
                        <p>Inst</p>
                        <a href="https://instagram.com/lolp1ke" target="_blank" rel="noreferrer">Lolp1ke</a>
                    </div>
                    <Link className="detail-info__button" to="/">Main page</Link>
                </div>
            </div>
        </section>
    )
}

export default P404Content;