import React from "react";

import "../css/default.css";
import "../css/titleslist.css";

function ListOfTitles() {
    return (
        <section className="titles-list">
        </section>
    )
}

export default ListOfTitles;