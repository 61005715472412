import React, { useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import Header from "../components/Header";
import titlesData from "./titlesData.json"

import "../css/chapters.css"

function Chapters() {
    let titleName = useParams().title.toLowerCase();
    let chapterNum = parseInt(useParams().chapter);
    let titleIndex = -1;
    let index = 0;
    titlesData.forEach(title => {
        if (title.titleName.toLowerCase() === titleName) {
            titleIndex = index;
        }
        else {
            index++;
        }
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (titleIndex === -1 || chapterNum <= 0 || chapterNum > titlesData[titleIndex].pages.length) {
            navigate("/404"); // fix
        }
    })

    let pages = titlesData[titleIndex].pages[chapterNum - 1];
    let fix = []

    for (let index = 1; index <= pages; index++) {
        fix.push(index)
    }

    const top = useRef(null);
    function ScrollToTop(ref) {
        useRef(ref);
    }

    if (titleIndex !== -1) {
        return (
            <main>
                <Header/>
                <section ref={top} className="hero-chapters">
                    <h1 className="hero-chapters__title">{titleName} {chapterNum} Chapter</h1>
                    <p className="hero-chapters__text" dangerouslySetInnerHTML={{__html: titlesData[titleIndex].description}}></p>
                </section>
                <section className="gallery">
                    {
                        fix.map((pageNum, key) => {
                            return (
                                <div key={key} className="gallery__item">
                                    <img loading="lazy" className="gallery__image" src={process.env.PUBLIC_URL + "/titles/" + titleName + "/chapter" + chapterNum + "/" + pageNum + ".webp"} alt={"page " + pageNum + " can't load or doesn't exist"}/>
                                </div>
                            )
                        })
                    }
                </section>
                <div className="buttons">
                    <Link to={"/title/" + titleName + "/chapter/" + (chapterNum - 1)} className="buttons__button" onClick={ScrollToTop}>Previus Chapter</Link>
                    <Link to={"/title/" + titleName + "/chapter/" + (chapterNum + 1)} className="buttons__button" onClick={ScrollToTop}>Next Chapter</Link>
                </div>
            </main>
        )
    }
}

export default Chapters;