import React from "react";
import Header from "../components/Header";
import AboutContent from "../components/AboutContent";

function About() {
    return (
        <>
            <Header/>
            <AboutContent/>
        </>
    )
}

export default About;