import React from "react";

import "../css/default.css";
import "../css/hero.css";

function Hero(title, text) {
    return (
        <section className="hero">
            <h2 className="hero__title" dangerouslySetInnerHTML={{__html: title}}></h2>
            <p className="hero__text" dangerouslySetInnerHTML={{__html: text}}></p>
        </section>
    )
}

export default Hero;